@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

.App {
  display: flex;
  flex-direction: column;
  color: black;
  background-color: white;
}

.ColumnA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-right: 0%;
}

.ColumnB {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ColumnB img {
  max-width: 90%;
  max-height: 90%;
}

.Title {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Title h1 {
  font-family: 'Playfair Display, serif';
  /*font-family: 'Caprasimo, serif';*/
  font-weight: bold;
  font-size: calc(24px + 6vw);
}

.ContactForm {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .ContactForm {
    flex-direction: column;
  }
}

.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  color: black;
  text-align: center;
  padding: 10px 0;
}

.Row {
  display: flex;
  justify-content: center;
  align-items: center;
}


input {
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

button {
  padding: 10px 20px;
  margin: 10px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
}


